import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from 'react-bootstrap';
import logo from './logo.svg';
import LazyLoad from 'react-lazyload';
import TimeAgo from 'react-timeago';

const Article = ({ item, showImage, hidden, condensedStyle, darkModeOn }) => {

    if (hidden) {
        return null;
    }

    const currentMillis = new Date().getTime();
    const articleMillis = new Date(item.pubDate.replace(' ', 'T') + 'Z').getTime();
    const oldThreshold = 1000 * 60 * 60 * 48; // 48 hours

    if (currentMillis - articleMillis > oldThreshold) {
        return null; // too old!
    }

    const imageError = (e) => {
        e.target.src = logo;
    }

    const goToSource = (sourceLink) => {
        window.open(sourceLink, '_blank');
    }

    let cardStyleNormal = { marginRight: '1em', marginBottom: '1em', display: 'inline-flex', maxWidth: '28em', minWidth: '10em', cursor: 'pointer' };
    let cardStyleCondensed = { minWidth: '10em', cursor: 'pointer' };
    let cardStyle = (condensedStyle) ? cardStyleCondensed : cardStyleNormal;
    let cardTitleStyle = { fontSize: '0.95em' };
    if (darkModeOn) {
        let darkStyle = { ...cardStyle };
        darkStyle.backgroundColor = '#222';
        cardStyle = darkStyle;
        let darkCardTitleStyle = { ...cardTitleStyle };
        darkCardTitleStyle.color = "#bbb"
        cardTitleStyle = darkCardTitleStyle;
    }
    let sourceImage = (item.image == null) ? logo : item.image;
    let condensedImage = <LazyLoad offset={200} once placeholder={<img alt={item.title} src={logo} height={50} width={50} />}><img alt={item.title} src={sourceImage} onError={imageError} style={{ float: 'left', marginRight: '1em', height: '50px', width: '50px', objectFit: 'cover', objectPosition: 'center' }} /></LazyLoad>;
    let image = (!showImage) ? null : condensedStyle ? condensedImage : (
        <LazyLoad height={50} offset={100}><Card.Img alt={item.title} variant="top" src={sourceImage} onError={imageError} style={{ height: '200px', objectFit: 'cover', objectPosition: 'center' }} /></LazyLoad>
    );
    let timestamp = <TimeAgo date={item.pubDate + "Z"} />;
    let cardInfoCondensed = <div style={{ marginTop: '0.5em' }}><small style={{ color: '#999' }} >< span style={{ marginRight: '1em' }}>{item.sourceSite}</span>{timestamp}</small></div>;
    let cardInfoNormal = <small style={{ color: '#999' }} >{timestamp}< span style={{ float: 'right' }}>{item.sourceSite}</span></small>;
    let cardInfo = condensedStyle ? cardInfoCondensed : cardInfoNormal;

    return (
        <>
            <Card style={cardStyle} onClick={() => goToSource(item.link)}>
                {condensedStyle ? null : image}
                <Card.Body>
                    <div style={{ display: 'block' }}>
                        {!condensedStyle ? null : image}
                        <Card.Title style={cardTitleStyle}>{item.title}</Card.Title>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    {cardInfo}
                </Card.Body>
            </Card >
        </>
    );
}

export default Article;