import logo from './logo_icon.svg';
const Header = () => {
    let icon = <span className="navbar-brand"><img className="d-inline-block align-top" alt="Wedgekase Games" src={logo} width="30" height="30" /></span>;
    return (
        <>
            <nav className="navbar navbar-dark bg-dark">
                <div className="container-fluid">
                    {icon}
                    <span className="h4" style={{ color: '#aaa', paddingTop: '7px' }}>Wedgekase Games</span>
                    <button className="navbar-toggler float-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>

            </nav>
        </>
    );
}

export default Header;