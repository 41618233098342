import Article from './Article';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Header from './Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';

function App() {

  const getFromLocalStorage = (pref, defaultValue) => {
    return pref == null ? defaultValue : pref === "true";
  }

  let [cardData, updateCardData] = useState(null);
  let [ignArticlesOn, updateIgnArticles] = useState(getFromLocalStorage(localStorage.ignArticlesOn, true));
  let [ignReviewsOn, updateIgnReviews] = useState(getFromLocalStorage(localStorage.ignReviewsOn, true));
  let [gamasutraOn, updateGamasutra] = useState(getFromLocalStorage(localStorage.gamasutraOn, true));
  let [gameInformerOn, updateGameInformer] = useState(getFromLocalStorage(localStorage.gameInformerOn, true));
  let [gameSpotOn, updateGameSpot] = useState(getFromLocalStorage(localStorage.gameSpotOn, true));
  let [gameSpotReviewsOn, updateGameSpotReviews] = useState(getFromLocalStorage(localStorage.gameSpotReviewsOn, true));
  let [destructoidOn, updateDestructoid] = useState(getFromLocalStorage(localStorage.destructoidOn, true));
  let [massivelyOpOn, updateMassivelyOp] = useState(getFromLocalStorage(localStorage.massivelyOpOn, true));
  let [nicheGamerOn, updateNicheGamer] = useState(getFromLocalStorage(localStorage.nicheGamerOn, false));
  let [rockPaperShotgunOn, updateRockPaperShotgun] = useState(getFromLocalStorage(localStorage.rockPaperShotgunOn, true));
  let [dualShockersOn, udpateDualShockers] = useState(getFromLocalStorage(localStorage.dualShockersOn, false));
  let [gameInformerNewsOn, updateGameInformerNews] = useState(getFromLocalStorage(localStorage.gameInformerNewsOn, false));
  let [redditGameDealsOn, updateRedditGameDeals] = useState(getFromLocalStorage(localStorage.redditGameDealsOn, false));
  let [showImages, updateShowImages] = useState(getFromLocalStorage(localStorage.showImages, true));
  let [showCondensedStyle, updateCondensedStyle] = useState(getFromLocalStorage(localStorage.showCondensedStyle, false));
  let [darkModeOn, updateDarkMode] = useState(getFromLocalStorage(localStorage.darkModeOn, false));

  const IGN_GAMES = 0;
  const IGN_REVIEWS = 1;
  const GAMASUTRA = 2;
  const GAME_INFORMER = 3;
  const GAMESPOT_NEWS = 4;
  const GAMESPOT_REVIEWS = 5;
  const DESTRUCTOID = 6;
  const MASSIVELY_OP = 7;
  const NICHE_GAMER = 8;
  const RPS = 9;
  const DUALSHOCKERS = 10;
  const GAME_INFORMER_NEWS = 11;
  const REDDIT_GAMEDEALS = 12;

  const sources = [
    { site: 'IGN - Game Articles', url: 'http://feeds.feedburner.com/ign/games-all' },
    { site: 'IGN - Game Reviews', url: 'http://feeds.feedburner.com/ign/game-reviews' },
    { site: 'Gamasutra', url: 'http://feeds.feedburner.com/GamasutraNews' },
    { site: 'Game Informer', url: 'https://www.gameinformer.com/rss.xml' },
    { site: 'GameSpot News', url: 'https://www.gamespot.com/feeds/game-news' },
    { site: 'GameSpot Reviews', url: 'https://www.gamespot.com/feeds/reviews' },
    { site: 'Destructoid', url: 'https://feeds.feedburner.com/Destructoid-Rss' },
    { site: 'Massively Op', url: 'https://massivelyop.com/feed/' },
    { site: 'Niche Gamer', url: 'https://nichegamer.com/feed/' },
    { site: 'Rock, Paper, Shotgun', url: 'http://feeds.feedburner.com/RockPaperShotgun' },
    { site: 'Dualshockers' },
    { site: 'Game Informer News' },
    { site: 'Reddit GameDeals' }
  ];



  const updateSource = ({ target }) => {
    switch (Number(target.id)) {
      case IGN_GAMES:
        localStorage.ignArticlesOn = target.checked;
        updateIgnArticles(target.checked);
        break;
      case IGN_REVIEWS:
        localStorage.ignReviewsOn = target.checked;
        updateIgnReviews(target.checked);
        break;
      case GAMASUTRA:
        localStorage.gamasutraOn = target.checked;
        updateGamasutra(target.checked);
        break;
      case GAME_INFORMER:
        localStorage.gameInformerOn = target.checked;
        updateGameInformer(target.checked);
        break;
      case GAMESPOT_NEWS:
        localStorage.gameSpotOn = target.checked;
        updateGameSpot(target.checked);
        break;
      case GAMESPOT_REVIEWS:
        localStorage.gameSpotReviewsOn = target.checked;
        updateGameSpotReviews(target.checked);
        break;
      case DESTRUCTOID:
        localStorage.destructoidOn = target.checked;
        updateDestructoid(target.checked);
        break;
      case MASSIVELY_OP:
        localStorage.massivelyOpOn = target.checked;
        updateMassivelyOp(target.checked);
        break;
      case NICHE_GAMER:
        localStorage.nicheGamerOn = target.checked;
        updateNicheGamer(target.checked);
        break;
      case RPS:
        localStorage.rockPaperShotgunOn = target.checked;
        updateRockPaperShotgun(target.checked);
        break;
      case DUALSHOCKERS:
        localStorage.dualShockersOn = target.checked;
        udpateDualShockers(target.checked);
        break;
      case GAME_INFORMER_NEWS:
        localStorage.gameInformerNewsOn = target.checked;
        updateGameInformerNews(target.checked);
        break;
      case REDDIT_GAMEDEALS:
        localStorage.redditGameDealsOn = target.checked;
        updateRedditGameDeals(target.checked);
        break;
      default: break;
    }
  }

  const shouldHideArticle = (sourceSite) => {
    switch (sourceSite) {
      case sources[IGN_GAMES].site: return !ignArticlesOn;
      case sources[IGN_REVIEWS].site: return !ignReviewsOn;
      case sources[GAMASUTRA].site: return !gamasutraOn;
      case sources[GAME_INFORMER].site: return !gameInformerOn;
      case sources[GAMESPOT_NEWS].site: return !gameSpotOn;
      case sources[GAMESPOT_REVIEWS].site: return !gameSpotReviewsOn;
      case sources[DESTRUCTOID].site: return !destructoidOn;
      case sources[MASSIVELY_OP].site: return !massivelyOpOn;
      case sources[NICHE_GAMER].site: return !nicheGamerOn;
      case sources[RPS].site: return !rockPaperShotgunOn;
      case sources[DUALSHOCKERS].site: return !dualShockersOn;
      case sources[GAME_INFORMER_NEWS].site: return !gameInformerNewsOn;
      case sources[REDDIT_GAMEDEALS].site: return !redditGameDealsOn;
      default: break;
    }
    return false;
  }

  const SourceCheckbox = ({ title, index, checked, onChange }) => {
    return (
      <div style={{ marginLeft: '1em', marginRight: '1em', marginBottom: '1em', display: 'inline-flex' }} className="form-check form-switch" key={index}>
        <input className="form-check-input" type="checkbox" id={index} checked={checked} onChange={onChange} />
        <label className="form-check-label text-white" htmlFor={index}>{title}</label>
      </div>
    );
  }

  let cards = (cardData == null) ? null : cardData.map((item, index) => {
    return <Article item={item} key={index} hidden={shouldHideArticle(item.sourceSite)} showImage={showImages} condensedStyle={showCondensedStyle} darkModeOn={darkModeOn} />;
  });

  useEffect(() => {
    axios.get('data.json')
      .then(response => {
        updateCardData(response.data);
      });
  }, []);

  let normalStyle = { width: '100%', height: '100%', paddingLeft: '1em', paddingTop: '1em' };
  let condensedStyle = { width: '100%', height: '100%' };
  let containerStyle = (showCondensedStyle) ? condensedStyle : normalStyle;
  if (darkModeOn) {
    let darkStyle = { ...containerStyle };
    darkStyle.backgroundColor = '#111';
    containerStyle = darkStyle;
  }

  return (
    <>
      <div className="collapse" id="navbarToggleExternalContent">
        <div className="bg-dark p-4">
          <h5 className="text-white h4">About</h5>
          <span className="text-muted">This is an aggregate site. No original content is hosted here. The source of the articles is shown in their respective cards.</span>
          <h5 className="text-white h4">Sources</h5>
          <form>
            <SourceCheckbox title={sources[DESTRUCTOID].site} index={DESTRUCTOID} checked={destructoidOn} onChange={updateSource} />
            <SourceCheckbox title={sources[DUALSHOCKERS].site} index={DUALSHOCKERS} checked={dualShockersOn} onChange={updateSource} />
            <SourceCheckbox title={sources[GAMASUTRA].site} index={GAMASUTRA} checked={gamasutraOn} onChange={updateSource} />
            <SourceCheckbox title={sources[GAME_INFORMER].site} index={GAME_INFORMER} checked={gameInformerOn} onChange={updateSource} />
            <SourceCheckbox title={sources[GAME_INFORMER_NEWS].site} index={GAME_INFORMER_NEWS} checked={gameInformerNewsOn} onChange={updateSource} />
            <SourceCheckbox title={sources[GAMESPOT_NEWS].site} index={GAMESPOT_NEWS} checked={gameSpotOn} onChange={updateSource} />
            <SourceCheckbox title={sources[GAMESPOT_REVIEWS].site} index={GAMESPOT_REVIEWS} checked={gameSpotReviewsOn} onChange={updateSource} />
            <SourceCheckbox title={sources[IGN_GAMES].site} index={IGN_GAMES} checked={ignArticlesOn} onChange={updateSource} />
            <SourceCheckbox title={sources[IGN_REVIEWS].site} index={IGN_REVIEWS} checked={ignReviewsOn} onChange={updateSource} />
            <SourceCheckbox title={sources[MASSIVELY_OP].site} index={MASSIVELY_OP} checked={massivelyOpOn} onChange={updateSource} />
            <SourceCheckbox title={sources[NICHE_GAMER].site} index={NICHE_GAMER} checked={nicheGamerOn} onChange={updateSource} />
            <SourceCheckbox title={sources[REDDIT_GAMEDEALS].site} index={REDDIT_GAMEDEALS} checked={redditGameDealsOn} onChange={updateSource} />
            <SourceCheckbox title={sources[RPS].site} index={RPS} checked={rockPaperShotgunOn} onChange={updateSource} />
          </form>
          <h5 className="text-white h4">Other Settings</h5>
          <SourceCheckbox title="Show Images" index={1000} checked={showImages} onChange={({ target }) => {
            localStorage.showImages = target.checked;
            updateShowImages(target.checked);
          }} />
          <SourceCheckbox title="Condensed Style" index={1001} checked={showCondensedStyle} onChange={({ target }) => {
            localStorage.showCondensedStyle = target.checked;
            updateCondensedStyle(target.checked)
          }} />
          <SourceCheckbox title="Dark Mode" index={1002} checked={darkModeOn} onChange={({ target }) => {
            localStorage.darkModeOn = target.checked;
            updateDarkMode(target.checked)
          }} />
        </div>
      </div>
      <Header />
      <div style={containerStyle}>
        {cards}
      </div>

    </>
  );
}

export default App;
